import React from 'react';
import { graphql } from 'gatsby';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import Layout from '../components/Layout';
import PageHeader from '../components/PageHeader';
import CategoryProductList from '../components/CategoryProductList';

const ProductCategoryTemplate = ({
  data: {
    shopifyCollection: { title, seo },
    inStockProducts,
    outOfStockProducts,
    datoCmsSite: {
      globalSeo: { titleSuffix },
    },
  },
}) => (
  <Layout>
    <HelmetDatoCms>
      <title>{`${seo.title || title}${titleSuffix}`}</title>
      {seo.description && <meta name="description" content={seo.description} />}
    </HelmetDatoCms>
    <main>
      <PageHeader
        heading={title}
        breadcrumbs={[{ slug: 'shop', text: 'Shop' }]}
      />
      <CategoryProductList
        items={[...inStockProducts.nodes, ...outOfStockProducts.nodes]}
      />
    </main>
  </Layout>
);

export const ProductCategoryTemplateQuery = graphql`
  query ProductCategoryTemplateQuery($id: String!) {
    shopifyCollection(id: { eq: $id }) {
      title
      seo {
        title
        description
      }
    }
    inStockProducts: allShopifyProduct(
      filter: {
        collections: { elemMatch: { id: { eq: $id } } }
        totalInventory: { gt: 0 }
      }
      sort: { fields: updatedAt, order: DESC }
    ) {
      nodes {
        ...ProductCardFragment
      }
    }
    outOfStockProducts: allShopifyProduct(
      filter: {
        collections: { elemMatch: { id: { eq: $id } } }
        totalInventory: { eq: 0 }
      }
      sort: { fields: updatedAt, order: DESC }
    ) {
      nodes {
        ...ProductCardFragment
      }
    }
    datoCmsSite {
      globalSeo {
        titleSuffix
      }
    }
  }
`;

export default ProductCategoryTemplate;
